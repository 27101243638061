import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import { Warning } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  noterow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(8),
  },
  icon: {
    fontSize: theme.typography.fontSize['6xl'],
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

function WarningNote({ className, text }) {
  const classes = useStyles()
  return (
    <div className={classNames(className, classes.noterow)}>
      <Warning className={classes.icon} />
      <Typography variant="h5">{text}</Typography>
      <Warning className={classes.icon} />
    </div>
  )
}

WarningNote.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
}

export default WarningNote
